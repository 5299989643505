<template>
  <div>
    <v-form v-model="valid" @submit.prevent="nextHandler">
      <v-row>
        <v-col cols="12" md="6">
          <base-date
            :placeholder="$t('shipmentDate')"
            v-model="obj.shipment_date"
            @handleDate="obj.shipment_date = $event"
          ></base-date>
        </v-col>

        <v-col cols="12" md="6" v-if="type == 'offline'">
          <base-select
            :placeholder="$t('category')"
            :items="categories"
            v-model="obj.category_id"
          ></base-select>
        </v-col>

        <v-col cols="12" md="9">
          <google-map
            @click="addLocation($event)"
            :location="destinationLocation"
            :markers="markers"
          ></google-map>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12" class="mt-0 pt-0">
              <span class="font-25 primary--text d-block mb-3">{{
                $t("destinationAddress")
              }}</span>
              <vue-google-autocomplete
                v-model="addressDestination"
                ref="address"
                id="map"
                outlined
                classname="form-control place-input mb-2"
                types="locality"
                v-on:placechanged="getAddressData($event)"
                :placeholder="$t('goToTheCity')"
              >
              </vue-google-autocomplete>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <view-field
                :key="keyUpdated"
                :value="obj.country"
                :title="$t('country')"
              ></view-field>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <view-field
                :key="keyUpdated"
                :value="obj.city"
                :title="$t('city')"
              ></view-field>
            </v-col>
            <v-col cols="12">
              <view-field
                :key="keyUpdated"
                :value="obj.address"
                :title="$t('address')"
              ></view-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex align-center justify-center">
        <v-btn
          class="c-btn mt-14 mx-1"
          color="grey"
          height="40"
          @click="$emit('back')"
          ><span class="white--text font-300 font-20 d-block px-12">{{
            $t("back")
          }}</span></v-btn
        >
        <v-btn
          :disabled="!valid"
          color="primary"
          type="submit"
          class="mt-14"
          width="150px"
          height="40px"
        >
          <span :class="!valid ? 'primary--text' : 'halfwhite--text'">
            {{ $t("submit") }}
          </span>

          <v-progress-circular
            v-if="loading"
            :size="15"
            color="lightGreyText"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoogleMap from "../../../components/base/GoogleMap.vue";
export default {
  components: { GoogleMap },
  props: {
    type: {
      type: String,
      default: "",
    },
    loading:{
      type:Boolean,
    }
  },
  data: () => ({
    obj: {
      latitude_destination: 173,
      longitude_destination: 133,
      shipment_date: "",
    },
    destinationCities: [],
    valid: false,
    country: "",
    city: "",
    address: "",
    markers: [{ lng: null, lat: null }],
    destinationLocation: { lat: 33.5138, lng: 36.2765 },

    addressDestination: "",
    reloaded: false,
    keyUpdated: false,
  }),
  computed: {
    ...mapGetters(["countries", "categories"]),
  },
  methods: {
    async addLocation(event) {
      let response = await this.$store.dispatch("setMapMarker", event);
      let lng = event.latLng.lng();
      let lat = event.latLng.lat();

      if (response.country) {
        this.obj.address = response.fullAddress;
        this.obj.country = response.country;
        this.obj.city = response.city;
        this.keyUpdated = !this.keyUpdated;
        this.markers[0].lng = lng;
        this.markers[0].lat = lat;
        this.obj.latitude_destination = lat;
        this.obj.longitude_destination = lng;
      } else {
        this.obj.address = "";
        this.obj.country = "";
        this.obj.city = "";
        this.$store.dispatch("showSnack", {
          text: "Not Available Address",
          color: "error",
          timeout: 2000,
        });
        this.keyUpdated = !this.keyUpdated;
      }
    },
    getAddressData(addressData) {
      if (addressData) {
        this.destinationLocation.lat = addressData.latitude;
        this.destinationLocation.lng = addressData.longitude;
      }
    },
    nextHandler() {
      this.$emit("submit", this.obj);
    },
  },
  mounted() {
    setTimeout(() => {
      this.reloaded = true;
    }, 1000);
  },
};
</script>

<style></style>
