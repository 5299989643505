<template>
  <div class="product-details">
    <div class="max-width">
      <v-row>
        <v-col cols="12" md="5">
          <div
            class="
              white
              radius-15
              dark-shadow
              product-details__slider
              pa-1
              pb-0
            "
          >
            <div class="product-details__slider__slide mx-auto">
              <img
                v-if="product.image.url"
                width="100%"
                class="object-cover mx-auto radius-15"
                :src="product.image.url"
                alt=""
              />
              <img
                width="100%"
                class="object-cover mx-auto radius-15"
                :src="product.image"
                alt=""
              />
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="7">
          <v-col cols="10">
            <v-row>
              <v-col cols="12">
                <div class="mt-4 mb-7">
                  <h2 class="size primary--text d-block ">{{
                    product.title
                  }}</h2>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex align-center">
                  <img
                    width="28"
                    height="28"
                    :src="require('@/assets/images/icons/weight.png')"
                    alt=""
                  />
                  <span class="font-30 mx-2 d-block black--text font-500"
                    >{{ product.weight + $t("kg") }}
                  </span>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="d-flex align-center">
                  <img
                  width="26"
                    height="26"
                    :src="require('@/assets/images/icons/prise.png')"
                    alt=""
                  />
                  <span class="font-30 mx-2 d-block black--text font-500"
                    >{{ product.price }}$
                  </span>
                </div>
              </v-col>

              <v-col cols="12" class="">
                <div class="d-flex align-center">
                  <img
                    width="24"
                    height="23"
                    :src="require('@/assets/images/icons/notes.png')"
                    alt=""
                  />
                  <span class="font-30 mx-2 d-block black--text font-500">
                    {{ $t("note") }}</span
                  >
                </div>
                <div class="py-1 c-btn white">
                  <span class="font-18 lightGreyText--text">{{
                    readMore
                      ? product.description
                      : product.description.slice(0, 200)
                  }}</span
                  ><span
                    v-if="!readMore && product.description.length > 200"
                    @click="readMore = true"
                    style="color: #89bbcb; font-size: 12px; cursor: pointer"
                  >
                    {{ $t("readMore") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="10" class="py-0 mt-1 mb-2">
                <div class="py-2 c-btn white url_v">
                  <a
                    :href="product.url"
                    target="_blank"
                    class="d-flex align-item-center justify-center"
                  >
                    <img
                      width="20"
                      height="20"
                      class="mx-2"
                      :src="require('@/assets/images/icons/link.png')"
                      alt=""
                    />
                    <span class="font-18 primary--text">{{
                      $t("visitLink")
                    }}</span>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-14 pb-3">
      <v-btn
        class="c-btn mx-1"
        color="grey"
        min-width="180"
        height="40"
        @click="$emit('back')"
        ><span class="white--text font-300 font-20">{{
          $t("back")
        }}</span></v-btn
      >
      <v-btn
        class="c-btn mx-1"
        color="secondary"
        min-width="180"
        height="40"
        @click="$emit('next')"
        ><span class="white--text font-300 font-20">{{
          $t("next")
        }}</span></v-btn
      >
      <!-- :disabled="!valid" -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    readMore: false,
  }),
  computed: {
    ...mapGetters(["categories"]),
  },
  methods: {},
};
</script>

<style lang="scss">
.product-details {
  .url_v {
    border: 1px solid $primary !important;
  }
  &__slider {
    max-width: 400px;
    margin: auto;
  }
}
</style>
